export default {
    appName: 'QTKEY管理系统',
    globalError: '发生错误,请联络技术人员',
    login: {
        emailLoginButton: '未携带装置登入',
        emailSended: '已发送 登入代码 至 <br> {email} <br>请尽速至信箱确认',
        passkeyLoginButton: 'QTKEY 登入',
        backButton: '返回',
    },
    redirect: {
        errorMessageTitle: '发生错误',
        toLoginMessage: '按下确定跳转至登入页面',
    },
    header: {
        projectName: 'QTKEY系统',
        language: {
            tw: '繁体',
            cn: '简体',
        },
        logout: '登出',
    },
    formTemplate: {
        upload: '上传档案',
        reset: '重填',
        submit: '确定送出',
        input: '输入',
        choose: '选择',
        beforeRemove: '确定移除',
        handleExceed: '最多上传一张,请先删除上一选项',
        error: '失败',
        cancel: '取消',
    },
    form: {
        emailRequireMessage: '请输入email',
        emailFormatMessage: 'email 格式错误',
        emailPlaceholder: '请输入email',
        buttonConfirm: '确定',
        search: '搜寻',
        clear: '清除',
        back: '返回',
    },
    table: {
        number: '编号',
        appName: '产品名称',
        status: '状态',
        operat: {
            columnName: '操作',
            manage: '管理',
            edit: '编辑',
            delete: '删除',
            recovery: '復原',
        },
        noData: '无资料...',
    },
    errorPage: {
        403: {
            p1: '啊哦~ 你没有权限访问该页面哦',
            p2: '返回首页',
            p3: '返回上一页',
        },
        404: {
            p1: '啊哦~ 你所访问的页面不存在',
            p2: '返回首页',
            p3: '返回上一页',
        },
    },
    dashboard: {
        greet: '欢迎登入 QTKEY系统，请点选左侧任一选单开始',
    },
    deviceBind: {
        emailLabel: '信箱(帐号)',
        errorMessageTitle: '错误',
        buttonBind: '绑定装置',
        toLoginMessage: '按下确定跳转至登入页面',
        bindSuccessMessage: '绑定成功',
        form: {
            nameLabel: '名称',
            deviceName: '装置名称',
            deviceNameRequire: '请输入装置名称',
        },
    },
    admin: {
        merchantList: {
            pageName: '商户管理',
            createAccount: '新增商户',
            editAccount: '编辑帐号',
            table: {
                merchantName: '商户名称',
                rpId: '商户站台位置',
            },
            form: {
                merchantNamePlaceholder: '请输入名称',
                rpIdPlaceholder: '请输入商户站台位置',
                statusOption: {
                    open: '启用',
                    close: '关闭',
                },
            },
            delete: {
                message: '删除帐号',
            },
            copy: '复製成功',
        },
        personalAccount: {
            descriptions: {
                title: '个人帐号管理',
                btnPasskey: '绑定Passkey',
                btnEdit: '编辑',
                name: '名称',
                email: '信箱',
                role: '角色',
                device: 'Passkey',
            },
            editAccount: '编辑个人帐号',
            createDevice: '新增装置名称',
            form: {
                device: '装置名称',
                devicePlaceholder: '请输入装置名称',
                name: '名称',
                namePlaceholder: '请输入名称',
                email: '信箱(帐号)',
                emailPlaceholder: '请输入信箱(帐号)',
            },
            messageSuccess: '绑定成功',
        },
        backStageAccount: {
            createAccount: '新增帐号',
            editAccount: '编辑帐号',
            createDevice: '新增装置名称',
            table: {
                email: '信箱(帐号)',
                name: '名称',
                roleName: '角色',
                btnBindKey: '绑定Passkey',
                btnSendMessage: '邀请绑定Passkey',
            },
            form: {
                device: '装置名称',
                devicePlaceholder: '请输入装置名称',
                name: '名称',
                namePlaceholder: '请输入名称',
                email: '信箱(帐号)',
                emailPlaceholder: '请输入信箱(帐号)',
                roleId: '角色',
                status: '状态',
                sendPasskey: '寄送Passkey绑定邀请信',
                statusOption: {
                    open: '启用',
                    close: '关闭',
                    y: '是',
                    n: '否',
                },
            },
            messageSuccess: '绑定成功',
            confirm: {
                sendEmail: '寄送Passkey邀请信至',
                delete: '是否确认删除?<br/>如果确认删除，请输入',
                errorMessage: '此栏位不可空白',
                cancel: '取消',
                confirm: '确定',
            },
        },
        rolePermissions: {
            createRole: '新增角色',
            editRole: '编辑角色',
            table: {
                name: '用户名称',
                remark: '角色描述',
            },
            tree: {
                title: '权限设定',
                adminTitle: '管理站系统权限',
                appTitle: '商户控制权限',
            },
            form: {
                name: '角色名称',
                namePlaceholder: '请输入角色名称',
                remark: '角色叙述',
                remarkPlaceholder: '请输入角色叙述',
            },
            confirm: {
                delete: '是否确认删除?<br/>如果确认删除，请输入',
                errorMessage: '此栏位不可空白',
                cancel: '取消',
                confirm: '确定',
            },
        },
        operationRecord: {
            table: {
                adminName: '用户名称',
                adminId: '用户编号',
                url: '操作项目',
                requestData: 'Request',
                createdAt: '执行时间',
                xForwarded: 'IP位置',
                responseCode: '状态',
            },
        },
    },
    merchant: {
        merchantDetail: {
            title: '商户首页',
            name: '名称',
            createTime: '创建时间',
            status: '状态',
            statusOption: {
                open: '启用',
                close: '关闭',
            },
        },
        appManagement: {
            create: '新增',
            detail: '详细资讯',
            table: {
                appName: '产品名称',
                detail: '详情',
                type: '服务类型',
            },
            confirm: {
                delete: '是否确认删除?<br/>如果确认删除，请输入',
                errorMessage: '此栏位不可空白',
                cancel: '取消',
                confirm: '确定',
            },
            form: {
                appType: '产品类型',
                appName: '产品名称',
                appNamePlaceholder: '请输入产品名称',
                rpName: '认证服务名称',
                rpNamePlaceholder: '请输入产品的英文名称',
                rpNameTip: '*格式范例:<span style="color:#543fff">qt-key</span>',
                rpId: '认证网址',
                rpIdPlaceholder: '请输入认证网址',
                rpIdTip: '*请输入专案服务的完整网址，QTKEY功能才可生效，<br>格式范例:<span style="color:#543fff">www.qtkey.com</span>',
                rpIdOAuth: 'OAuth2平台网址',
                rpIdOAuthPlaceholder: '请输入OAuth2平台网址',
                rpIdOAuthTip: '注意:域名决定后无法变更。如强制变更，因Passkey规范，用户之Passkey阶会失效，需要重新绑定。如需强制变更，请联络我们。',
                redirect: '服务callback位置',
                redirectPlaceholder: '请输入服务callback位置',
                androidApkKeyPlaceholder: 'ex:android:apk-key-hash:xxxxxxx',
                androidApkKeyTip: '*请输入您的Android API Key Hash，以允许您的应用建立和存取QTKEY',
                status: '状态',
                statusOption: {
                    open: '启用',
                    close: '关闭',
                },
            },
            detailTab: {
                authentication: 'Authentication管理',
                userManagement: '产品用户管理',
                events: 'Events纪录',
                deletedEvents: '产品用户删除纪录',
                basic: '基本管理',
                emailProvider: 'Email Provider',
            },
            authentication: {
                form: {
                    magicLink: 'Magic Link过期时间(秒)',
                    magicLinkPlaceholder: '请输入Magic Link过期时间(秒)',
                },
            },
            userManagement: {
                create: '新增用户',
                import: '汇入用户',
                edit: '编辑用户',
                emailListDialogTitle: '重复Email',
                importSuccess: '汇入成功',
                table: {
                    identity: '用户帐号',
                    email: '信箱',
                    createdAt: '注册日期',
                    lastLogin: '上次登入时间',
                    events: '纪录',
                    ipAddress: '白名单IP',
                },
                confirm: {
                    delete: '是否确认删除?<br/>如果确认删除，请输入',
                    errorMessage: '此栏位不可空白',
                    cancel: '取消',
                    confirm: '确定',
                },
                form: {
                    identity: '用户帐号',
                    email: '信箱',
                    status: '状态',
                    statusOption: {
                        open: '启用',
                        close: '关闭',
                    },
                    downloadImportDemo: '下载范例档案',
                    uploadFile: '选择汇入档案',
                    ipAddress: '白名单IP',
                },
            },
            deletedEvents: {
                edit: '编辑用户',
                table: {
                    email: '用户帐号',
                    createdAt: '注册日期',
                    deletedAt: '删除日期',
                    lastLogin: '上次登入时间',
                    events: '纪录',
                },
                confirm: {
                    recovery: '是否确认復原',
                    cancel: '取消',
                    confirm: '确定',
                },
                form: {
                    email: '用户帐号',
                    status: '状态',
                    statusOption: {
                        open: '启用',
                        close: '关闭',
                    },
                },
            },
            events: {
                table: {
                    adminName: '用户帐号',
                    adminId: '用户编号',
                    eventType: 'Event Type',
                    createdAt: '执行时间',
                    xForwarded: 'IP位置',
                    device: '装置',
                },
            },
            basicManagement: {
                form: {
                    appName: '产品名称',
                    appNamePlaceholder: '请输入产品名称',
                    MagicLink: 'Magic Link 验证路径',
                    MagicLinkPlaceholder: 'ex: /magic_link/verify',
                    MagicLinkTip: '*Magic Link登录信件中的跳转位置，该页面需验证Magic Link是否有效',
                    androidApkKeyPlaceholder: 'ex:android:apk-key-hash:xxxxxxx',
                    androidApkKeyTip: '*请输入您的Android API Key Hash，以允许您的应用建立和存取QTKEY',
                    rpIdTip: '*提醒，如变更认证网址，用户需重新绑定QTKEY，请提前做好准备',
                    rpName: '认证服务名称',
                    rpId: '认证网址',
                    status: '状态',
                    popconfirm: {
                        applicationId: '确定要重新设置Application Id?',
                        apiSecretKey: '确定要重新设置API SECRET KEY?',
                    },
                    statusOption: {
                        open: '启用',
                        close: '关闭',
                    },
                },
            },
            OAuthManagement: {
                createOAuth: '新增服务',
                editOAuth: '编辑服务',
                addressTile: 'OAuth2串接地址',
                tableTitle: 'OAuth2 服务',
                table: {
                    name: '名称',
                    redirect: '服务callback位置',
                },
                form: {
                    appName: '产品名称',
                    status: '状态',
                    appNamePlaceholder: '请输入产品名称',
                    rpIdOAuth: 'OAuth2平台网址',
                    rpIdOAuthPlaceholder: '请输入OAuth2平台网址',
                    register: '开放注册',
                    redirect: '服务callback位置',
                    redirectPlaceholder: '请输入服务callback位置',
                    popconfirm: {
                        secretKey: '确定要重新设置SECRET KEY?',
                    },
                },
                statusOption: {
                    open: '启用',
                    close: '关闭',
                },
                oauthConnectUrlLable: {
                    authorize: '授权端点地址',
                    logout: '注销会话端点地址',
                    token: 'Token端点地址',
                    user: '用户讯息端点地址',
                },
            },
        },
        MerchantAccount: {
            createAccount: '新增帐号',
            editAccount: '编辑帐号',
            createDevice: '新增装置名称',
            table: {
                email: '信箱(帐号)',
                name: '名称',
                roleName: '角色',
                btnBindKey: '绑定Passkey',
                btnSendMessage: '邀请绑定Passkey',
            },
            form: {
                device: '装置名称',
                devicePlaceholder: '请输入装置名称',
                name: '名称',
                namePlaceholder: '请输入名称',
                email: '信箱(帐号)',
                emailPlaceholder: '请输入信箱(帐号)',
                roleId: '角色',
                status: '状态',
                sendPasskey: '寄送Passkey绑定邀请信',
                statusOption: {
                    open: '启用',
                    close: '关闭',
                    y: '是',
                    n: '否',
                },
            },
            messageSuccess: '绑定成功',
            confirm: {
                sendEmail: '寄送Passkey邀请信至',
                delete: '是否确认删除?<br/>如果确认删除，请输入',
                errorMessage: '此栏位不可空白',
                cancel: '取消',
                confirm: '确定',
            },
        },
        rolePermissions: {
            createRole: '新增角色',
            editRole: '编辑角色',
            table: {
                name: '用户名称',
                remark: '角色描述',
            },
            tree: {
                title: '权限设定',
                merchantTitle: '商户站系统权限',
                appTitle: '产品管理',
            },
            form: {
                name: '角色名称',
                namePlaceholder: '请输入角色名称',
                remark: '角色叙述',
                remarkPlaceholder: '请输入角色叙述',
            },
            confirm: {
                delete: '是否确认删除?<br/>如果确认删除，请输入',
                errorMessage: '此栏位不可空白',
                cancel: '取消',
                confirm: '确定',
            },
        },
        operationRecord: {
            table: {
                name: '用户名称',
                accountId: '用户编号',
                url: '操作项目',
                requestData: 'Request',
                createdAt: '执行时间',
                xForwarded: 'IP位置',
                responseCode: '状态',
            },
        },
    },
}
