import axios from 'axios'
import { getCookie, setCookie, deleteCookie } from './cookie'
import router from '../router/index'

const service = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/api/admin/`,
    timeout: 15000,
})

service.interceptors.request.use(
    (config) => {
        let token = getCookie('__tk')
        if (token && config.url !== '/login') {
            config.headers.Authorization = token
        }
        return config
    },
    (error) => {
        console.log(error)
        return Promise.reject()
    }
)

service.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            // 是否需要回到登入頁面
            if (response.data.code == 501) {
                deleteCookie('__tk')
                router.push('/login')
                return Promise.reject('Auth denied redirect to login')
            }
            // 是否需要回到登入頁面
            if (response.data.code == 403) {
                router.push('/403')
                return Promise.reject('403')
            }
            // 如果回傳的資料有 Authorization 則需要重新設定 cookie
            if (response.data.Authorization) {
                setCookie('__tk', response.data.Authorization, 14, 'days')
            }

            return response.data
        } else {
            return Promise.reject()
        }
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default service
