import { createRouter, createWebHistory } from 'vue-router'
import basicRoute from '@/router/basic.js'
import adminRoute from '@/router/admin.js'
import merchantRoute from '@/router/merchant.js'

const routes = [...basicRoute, ...adminRoute, ...merchantRoute]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
})

//判斷有無權限
// const checkRouter = (weblink) => {
// let canSee = 200
// let data = {
//     web_link: weblink,
// }
// return new Promise((resolve) => {
//     adminPermission(data).then((res) => {
//         if (res.code == 200) {
//             canSee = 200
//         } else if (res.code == 403) {
//             canSee = 403
//         } else if (res.code == 404) {
//             canSee = 404
//         } else if (res.code == 500) {
//             canSee = 500
//         }
//         resolve(canSee)
//     })
// })
// }
// router.beforeEach((to, from, next) => {
// // 看cookie 裡面有沒有token, 沒有的話返回login頁面
// let token = getCookie('__tk')
// if (!token && to.path !== '/login') {
//     next('/login')
// } else {
//     if (to.path !== '/403' && to.path !== '/404' && to.path !== '/login') {
//         checkRouter(to.path.substr(1)).then((weblink) => {
//             if (weblink == 200) {
//                 next()
//             } else if (weblink == 403) {
//                 next('/403')
//             } else if (weblink == 404) {
//                 next('/404')
//             } else if (weblink == 500) {
//                 next(from.path)
//             }
//             // console.log(weblink)
//             // next()
//         })
//     } else {
//         next()
//     }
// }
// })

export default router
