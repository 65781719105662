export const setCookie = (name, value = '', time, type = 'days', path = '/') => {
    let expires = new Date()

    switch (type) {
        case 'days':
        default:
            expires.setTime(expires.getTime() + time * 24 * 60 * 60 * 1000)
            break
        case 'hour':
            expires.setTime(expires.getTime() + time * 60 * 60 * 1000)
            break
    }

    document.cookie = `${name}=${value};expires=${expires.toGMTString()};path=${path}`
    return
}

export const getCookie = (name) => {
    let cookie = {}
    document.cookie.split(';').forEach(function (el) {
        let [key, value] = el.split('=')
        cookie[key.trim()] = value
    })
    return cookie[name]
}

export const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    return
}
