import ElementPlus from 'element-plus'
import localeTW from 'element-plus/dist/locale/zh-tw.mjs'
import localeCN from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'

export default (app) => {
    const local = localStorage.getItem('locale_i18n_nemo') || 'zh-tw'
    let elementLocal

    // 設定 Element plus 元件的多國語系
    switch (local) {
        case 'zh-cn':
            elementLocal = localeCN
        case 'zh-tw':
        default:
            elementLocal = localeTW
    }

    app.use(ElementPlus, { locale: elementLocal })
}
