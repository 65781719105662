// import Vue from 'vue'
import { createStore } from 'vuex'
import user from './modules/user'
import global from './modules/global'
import getters from './getters'

// Vue.use(Vuex)

export default createStore({
    modules: {
        user,
        global,
    },
    getters,
})
