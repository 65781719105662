import { routeMiddleware } from '@/utils/middleware'

export default [
    {
        path: '/merchant/:id',
        component: () => import('@/layout/merchant.vue'),
        beforeEnter: routeMiddleware,
        children: [
            {
                path: 'detail',
                name: 'detail',
                meta: {
                    title: '商戶首頁',
                },

                component: () => import('@/views/Merchant/MerchantDetail/MerchantDetail.vue'),
            },
            {
                path: 'appManagement',
                name: 'appManagement',
                meta: {
                    title: '產品管理',
                },
                component: () => import('@/views/Merchant/AppManagement/AppManagement.vue'),
            },
            {
                path: 'system',
                name: 'merchant-system',
                redirect: 'noRedirect',
                meta: { title: '系統管理選單' },
                children: [
                    {
                        path: 'merchantAccount',
                        name: 'merchant-merchantAccount',
                        meta: {
                            title: '後台帳號管理',
                        },
                        component: () => import('@/views/Merchant/System/MerchantAccount/MerchantAccount.vue'),
                    },
                    {
                        path: 'rolePermissions',
                        name: 'merchant-rolePermissions',
                        meta: {
                            title: '角色權限管理',
                        },
                        component: () => import('@/views/Merchant/System/RolePermissions/RolePermissions.vue'),
                    },
                    {
                        path: 'operationRecord',
                        name: 'merchant-operationRecord',
                        meta: {
                            title: '操作紀錄管理',
                        },
                        component: () => import('@/views/Merchant/System/OperationRecord/OperationRecord.vue'),
                    },
                ],
            },
            // {
            //     path: 'appManagement',
            //     name: 'appManagement',
            //     meta: {
            //         title: 'APP管理',
            //     },
            //     component: () => import('@/views/Merchant/AppManagement/AppManagement.vue'),
            // },
            // {
            //     path: 'system/merchantAccount',
            //     name: 'merchant-merchantAccount',
            //     meta: {
            //         title: '商戶帳號管理',
            //     },
            //     component: () => import('@/views/Merchant/System/MerchantAccount/MerchantAccount.vue'),
            // },
            // {
            //     path: 'system/rolePermissions',
            //     name: 'merchant-rolePermissions',
            //     meta: {
            //         title: '角色權限管理',
            //     },
            //     component: () => import('@/views/Merchant/System/RolePermissions/RolePermissions.vue'),
            // },
            // {
            //     path: 'system/operationRecord',
            //     name: 'merchant-operationRecord',
            //     meta: {
            //         title: '操作紀錄管理',
            //     },
            //     component: () => import('@/views/Merchant/System/OperationRecord/OperationRecord.vue'),
            // },
        ],
    },
]
