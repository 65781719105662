<template>
    <router-view />
</template>

<script>
</script>

<style>
@import './assets/css/main.css';
@import './assets/css/color-dark.css';
</style>