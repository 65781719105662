import request from '@/utils/request'

/**後台-admin全域api */

//取得admin角色
export const getRole = (query) => {
    return request({
        url: 'global/getRole',
        method: 'get',
        params: query,
    })
}
//取得merchant角色
export const getMerchantRole = (query) => {
    return request({
        url: 'global/getMerchantRole',
        method: 'get',
        params: query,
    })
}
//取得商戶首頁
export const getMerchantDetail = (query) => {
    return request({
        url: 'merchantManage/detail',
        method: 'get',
        params: query,
    })
}
//啟用/停用passkey
export const updatePasskey = (query) => {
    return request({
        url: 'global/updatePasskey',
        method: 'post',
        data: query,
    })
}
