import { routeMiddleware } from '@/utils/middleware'
import { i18nInstance } from '@/plugins/i18n.js'

let i18n = i18nInstance()

export default [
    {
        path: '/admin',
        component: () => import('@/layout/admin.vue'),
        beforeEnter: routeMiddleware,
        children: [
            {
                path: 'merchantList',
                name: 'merchantList',
                meta: {
                    // title: '商戶管理',
                    title: i18n.global.t('admin.merchantList.pageName'),
                },
                component: () => import('@/views/Admin/MerchantList/MerchantList.vue'),
            },
            // {
            //     path: 'system/personalAccount',
            //     name: 'admin-personalAccount',
            //     meta: {
            //         title: '個人帳號管理',
            //     },
            //     component: () => import('@/views/Admin/System/PersonalAccount/PersonalAccount.vue'),
            // },
            // {
            //     path: 'system/backStageAccount',
            //     name: 'admin-backStageAccount',
            //     meta: {
            //         title: '後台帳號管理',
            //     },
            //     component: () => import('@/views/Admin/System/BackStageAccount/BackStageAccount.vue'),
            // },
            // {
            //     path: 'system/rolePermissions',
            //     name: 'admin-rolePermissions',
            //     meta: {
            //         title: '角色權限管理',
            //     },
            //     component: () => import('@/views/Admin/System/RolePermissions/RolePermissions.vue'),
            // },
            // {
            //     path: 'system/operationRecord',
            //     name: 'admin-operationRecord',
            //     meta: {
            //         title: '操作紀錄管理',
            //     },
            //     component: () => import('@/views/Admin/System/OperationRecord/OperationRecord.vue'),
            // },
            {
                path: 'system',
                name: 'admin-system',
                redirect: 'noRedirect',
                meta: { title: '系統管理選單' },
                children: [
                    {
                        path: 'personalAccount',
                        name: 'admin-personalAccount',
                        meta: {
                            title: '個人帳號管理',
                        },
                        component: () => import('@/views/Admin/System/PersonalAccount/PersonalAccount.vue'),
                    },
                    {
                        path: 'backStageAccount',
                        name: 'admin-backStageAccount',
                        meta: {
                            title: '後台帳號管理',
                        },
                        component: () => import('@/views/Admin/System/BackStageAccount/BackStageAccount.vue'),
                    },
                    {
                        path: 'rolePermissions',
                        name: 'admin-rolePermissions',
                        meta: {
                            title: '角色權限管理',
                        },
                        component: () => import('@/views/Admin/System/RolePermissions/RolePermissions.vue'),
                    },
                    {
                        path: 'operationRecord',
                        name: 'admin-operationRecord',
                        meta: {
                            title: '操作紀錄管理',
                        },
                        component: () => import('@/views/Admin/System/OperationRecord/OperationRecord.vue'),
                    },
                ],
            },
        ],
    },
]
