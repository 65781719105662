import { getRole, getMerchantRole, getMerchantDetail } from '@/api/global/global'
const global = {
    state: {
        roleList: [], //admin角色
        merchantRoleList: [], //merchant角色
        merchantInfo: [], //商戶資訊
    },
    mutations: {
        //admin角色
        setRoleList(state, data) {
            state.roleList = data
        },
        //merchant角色
        setMerchantRoleList(state, data) {
            state.merchantRoleList = data
        },
        //商戶資訊
        setMerchantInfo(state, data) {
            state.merchantInfo = data
        },
    },
    actions: {
        getRoleList({ commit }) {
            getRole()
                .then((res) => {
                    if (res.code == 200) {
                        var role = res.data.map((o) => {
                            return { value: o.id, label: o.name }
                        })
                        commit('setRoleList', role)
                    } else {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getMerchantRoleList({ commit }, merchantId) {
            getMerchantRole({ merchant_id: merchantId })
                .then((res) => {
                    if (res.code == 200) {
                        var role = res.data.map((o) => {
                            return { value: o.id, label: o.name }
                        })
                        commit('setMerchantRoleList', role)
                    } else {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getMerchantInfo({ commit }, merchantId) {
            getMerchantDetail({ merchant_id: merchantId })
                .then((res) => {
                    if (res.code == 200) {
                        commit('setMerchantInfo', res.data)
                    } else {
                        this.$message.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
}

export default global
