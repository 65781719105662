import { createI18n } from 'vue-i18n'
import messages from '@/utils/i18n/index.js'

const local = localStorage.getItem('locale_i18n_nemo') || 'zh-tw'

export const i18nInstance = () => {
    return createI18n({
        globalInjection: true,
        legacy: false,
        allowComposition: true,
        locale: local,
        messages: messages,
    })
}

export default (app) => {
    const i18n = i18nInstance()

    app.use(i18n)
}
